.main{
    display: flex;
    width: 100%;
    background-image:  "url(/17973908.jpg)";
    background-size: cover;
    background-position: center center
}

.mainnn{
  
        display: flex;
        width: 100%;
        background-image:  "url(/17973908.jpg)";
        background-size: cover;
        background-position: center center
    
    
}