
.Manuscript-title1 {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    
}

.text-container1 {
    display: flex;
    flex-direction: column;
    white-space: pre-wrap;
    word-wrap: break-word;
    text-align: left;
    height: 100vh;
    flex: 1;
    margin: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 14px;
    direction: rtl; /* set direction to right-to-left */
  }
  