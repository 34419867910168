.chart-container {
    width: 100%;
    height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  
  .dx-chart {
    width: 100%;
    height: 100%;
  }
  
  .dx-chart-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .dx-chart-legend {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .dx-legend-item {
    margin: 0 10px;
    display: flex;
    align-items: center;
    font-size: 14px;
    cursor: pointer;
  }
  
  .dx-tooltip-content {
    background-color: #ffffff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    padding: 10px;
    font-size: 14px;
    color: #333;
  }
  
  .dx-tooltip-arrow {
    border-color: #ccc transparent transparent transparent;
  }
  
  .dx-argument-axis {
    font-size: 14px;
  }
  
  .dx-value-axis {
    font-size: 14px;
  }
  
  .dx-axis-label {
    font-size: 14px;
  }
  
  .dx-axis-line {
    stroke: #ccc;
    stroke-width: 2px;
  }
  
  .dx-series-lines {
    stroke-width: 3px;
  }
  