
.Manuscript-title {
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.text-container {
     display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: left;
    flex: 1;
    margin: 20px;
    background-color: #fff;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.2);
    overflow-y: scroll;
    overflow-x: hidden;
    font-size: 14px;
}

.content-link {
    margin-bottom: 10px;
  }
  
  /* Offset for fixed header */
  :target::before {
    content: "";
    display: block;
    height: 60px;
    margin: -60px 0 0;
  }

  
