.navbar {
    display: flex;
    justify-content: center;
  }
  
  .navbar-nav {
    display: flex;
    list-style: none;
  }
  
  .nav-item {
    /* margin: 0 10px; */
    margin-right: 20px;
  }
  
  .nav-link {
    /* display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    color: #333; */
    
  
      display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: #333;
      padding: 10px;
      white-space: nowrap;
    
    
  }
  
  .nav-icon {
    font-size: 1.5rem;
    margin-bottom: 0.
  }

  .maing{
    display: flex;
      flex-direction: column;
      align-items: center;
      text-decoration: none;
      color: #333;
      padding: 10px;
      white-space: nowrap;
      background-color: rgb(176, 222, 241);
  }