.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;
}

h1 {
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: bold;
    font-family: inherit;
}

.file-input {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
}

label {
    font-size: 20px;
    margin-bottom: 10px;
    font-family: inherit;
}

input[type="file"] {
    font-size: 20px;
    padding: 10px;
    border: 1px solid gray;
    border-radius: 5px;
}

button[type="submit"] {
    font-size: 24px;
    background-color: #0077c2;
    color: white;
    padding: 10px 20px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #0061a1;
}