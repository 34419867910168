.auth-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  height: calc(100vh - 150px);
}

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  h1 {
    font-size: 44px;
    margin-bottom: 5px;
  }
  
  h2 {
    font-size: 24px;
    margin-bottom: 5px;
    width: 300px; /* Add this line to fix the width of the text container */
    display: inline-block; /* Add this line to make the container inline */
  }
  
  h3 {
    font-size: 15px;
    margin-bottom: 5px;
  }

  h4 {
    font-size: 10px;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  input {
    margin-bottom: 15px;
    padding: 15px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }
  
  button {
    padding: 10px;
    border-radius: 6px;
    border: none;
    background-color: #ff8400;
    color: #fff;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #005BFF;
  }
  